import { useState } from "react";
import { useEffect } from "react";
import Select from 'react-select';
import Swal from 'sweetalert2';
import swal from 'sweetalert2';
import { getActiveCheckInMaster, getViewCheckInByDate } from "../api/checkInAPI";

const CheckInReport = (props) => {

    const [selectedFromDate,                setSelectedFromDate]         = useState(moment().toDate());
    const [selectedToDate,                  setSelectedToDate]           = useState(moment().toDate());
    const [checkInTable,                    setCheckInTable]             = useState(null);
    const [isInit,                          setIsInit]                   = useState(true);
    const [checkInReport,                   setCheckInReport]            = useState([]);
    const [checkInMaster,                   setCheckInMaster]            = useState([]);
    const [type,                            setType]                     = useState({});
    
    useEffect(() => {
        props.title('CheckIn Report','checkIn-report')
        $('#txtFromDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtFromDate').on('change', function (event) {
            setSelectedFromDate(event.target.value)
        }); 
        $('#txtToDate').datepicker({
            format: 'dd-mm-yyyy',
            minDate: new Date(),
        });  
        $('#txtToDate').on('change', function (event) {
            setSelectedToDate(event.target.value)
        }); 
        fetchCheckInMaster();
    },[])

    useEffect(() => {       
        if (!isInit) {
            fillDataTable();
        }
    }, [checkInReport]);

    useEffect(() => {
        if(selectedFromDate && selectedToDate){
            fetchCheckInReport(moment(selectedFromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'), moment(selectedToDate, 'DD-MM-YYYY').format('YYYY-MM-DD'))
        }
    },[selectedFromDate, selectedToDate])

    useEffect(() => {
        if(type && !isInit){
            $('#tblCheckInReport').KTDatatable().destroy(); 
            fillDataTable();
        }
    },[type])

    const fetchCheckInMaster = async() => {
        try {
            const res =  await getActiveCheckInMaster();
            const masters = [{ID: '0', Name: 'All'}, ...res]
            masters.length > 0 && setType({'CheckInMasterID': masters[0].ID})
            setCheckInMaster(masters)        
        } catch (err) {
            swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    };

    const fetchCheckInReport = async(fdate, tDate) => {
        try {
            swal.fire({
                titleText: 'Loading',
                text: 'Please Wait...',
                showConfirmButton: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });
            const response = await getViewCheckInByDate(fdate, tDate)

            isInit ? setIsInit(false) : $('#tblCheckInReport').KTDatatable().destroy(); 
            setCheckInReport(response)
            swal.close();
        } catch (err) {
            Swal.fire({
                icon      : 'error',
                titleText : err.message
            })  
        }
    };

    function exportSheet(type) {
        checkInTable.update({
            headers: true,
            formats: ["xlsx", "csv"],
            filename: `${moment().format("DD-MM-YYYY")} - CheckIn Report` // pass in a new set of properties
        });

        if (type == 'xlsx') {
            $('.button-default.xlsx').click();
        } else {
            $('.button-default.csv').click();
        }
        $('.tableexport-caption').addClass('hidden');
    };

    function fillDataTable() {
        let reportList;

        if(type.CheckInMasterID == 0){
            reportList = checkInReport
        }else{
            reportList = checkInReport.filter(f => f.CheckInMasterID == type.CheckInMasterID)

        };

        const dataTable = $('#tblCheckInReport').KTDatatable({
            data: {
                saveState: false,
                source: reportList,
            },
            layout: {
                scroll: false,
                footer: false,
            },
            sortable: false,
            pagination: false,
            search: {
                input: $('#generalSearch'),
            },
            columns: [
                {
                    field: 'CreatedDate',
                    title: 'Check-In Date',
                    width: 110,
                    template: function (row) {
                        return (moment(row.CreatedDate).format("DD-MMM-YYYY"));
                    }
                },
                {
                    field: 'Time',
                    title: 'Time',
                    width: 60
                },
                {
                    field: 'MembershipType',
                    title: 'Membership Type',
                    width: 120

                },
                {
                    field: 'Name',
                    title: 'Facility',
                    width: 90   

                },
                {
                    field: 'UserName',
                    title: 'Name',
                    template: function (row) {
                        if (row?.UserName) {
                            return `<span class='kt-badge kt-badge--${row.UserType == "User" ? 'success' : (row.UserType == "Guest" || row.UserType == "UsersGuest") ? 'warning' : 'danger'} kt-badge--dot'></span>&nbsp;&nbsp;${row.UserName}`
                        }else {
                            return '';
                        }
                    },
                    width: 120,
                },
                {
                    field: 'MemberType',
                    title: 'Member Type',
                    width: 70,
                    width: 95,
                },
                {
                    field: 'MembershipNo',
                    title: 'Membership No',
                    width: 110,
                },
                {
                    field: 'Subscription',
                    title: 'Subscription',
                    width: 110,
                },
            
            ]
            ,
            rows: {
                autoHide: false,
            }
        });
        Config.Core.InitPopover();

        // export to excel
        const checkInTable = $('.kt-datatable__table').tableExport({
            formats: ["xlsx", "csv"],
            headers: true,
            filename: `${moment().format("DD-MM-YYYY")} - CheckIn Report`,
        });
        setCheckInTable(checkInTable);
        $('.tableexport-caption').addClass('hidden');
        
    }
    return(
        <>
            <div className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-tb0" id="kt_content"  >
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                    <div className="kt-portlet">
                        <div className="kt-portlet__body padding-t20 padding-b0">
                            <div id="ControlHolder" className="kt-form kt-form--label-right padding-10" role="form">
                                <div className="row">
                                <label className="col-form-label col-sm-1 text-right">From Date</label>
                                    <div className="form-group col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtFromDate" 
                                                name="txtFromDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedFromDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="col-form-label col-sm-1 text-right">To Date</label>
                                    <div className="form-group  col-sm-2">
                                        <div className="input-group date">
                                            <input 
                                                id="txtToDate" 
                                                name="txtToDate" 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Select date" 
                                                defaultValue={moment(selectedToDate, 'DD-MM-YYYY').format('DD-MM-YYYY')} 
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">
                                                    <i className="la la-calendar-check-o"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div  className="col-sm-1">
                                    </div> */}
                                    <label className="col-form-label col-sm-1 text-right">Facility</label>
                                    <div className="form-group col-sm-2">
                                        <div>
                                            <Select 
                                                id="ddlFacility" 
                                                name="ddlFacility"
                                                className="multi-custom width-95p"
                                                value={((checkInMaster && checkInMaster.length > 0 && type?.CheckInMasterID) && {label: (checkInMaster.find((m) => m.ID == type?.CheckInMasterID) && checkInMaster.find((m) => m.ID == type?.CheckInMasterID).Name), value: type.CheckInMasterID}) || ''}
                                                onChange={(event) => setType({CheckInMasterID: event.value})}
                                                options={checkInMaster.map(c => ({
                                                    label: c.Name,
                                                    value: c.ID
                                                }))}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group col-sm-2">
                                        <div className="input-group">
                                            <div className="kt-input-icon kt-input-icon--left">
                                                <input 
                                                    type="text" 
                                                    className="form-control clientSearch" 
                                                    placeholder="Search..." 
                                                    id="generalSearch" 
                                                />
                                                <span className="kt-input-icon__icon kt-input-icon__icon--left"><span><i className="la la-search"></i></span></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-1">
                                        <div className="dropdown">
                                            <button className="btn btn-label-brand dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Export
                                            </button>
                                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 38px, 0px)'}}>
                                                <button 
                                                    id="btn-export-excel" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('xlsx')}

                                                > Excel 
                                                </button>
                                                <button 
                                                    id="btn-export-csv" 
                                                    type="button" 
                                                    className="dropdown-item"
                                                    onClick={() => exportSheet('csv')}
                                                >  Csv </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="tblCheckInReport" className="table table-bordered table-hover custom-datatable"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CheckInReport;

