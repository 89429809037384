import React, { useState, useEffect, useRef } from "react";
import Dropzone from "../global/dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Swal from "sweetalert2";
import * as helper from "./helper";

const ImageCropper = (props) => {
    const { dataHandler, imageRatio, header } = props;
    
    const imgRef = useRef();

    const [file,         setFile]         = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [image,        setImage]        = useState(null);
    const [cropSetting,  setCropSetting]  = useState({ x : 0, y : 0, unit : 'px', width : imageRatio.width, height : imageRatio.height });
    const [scale,        setScale]        = useState(1);
 
    const onScale = ({target}) => setScale(target.value);  
          
    const AdjustCropper = (e) => {
        const img = e.currentTarget   
        setImage(img);

        if (img) {         
            if (img.width < imageRatio.width || img.height < imageRatio.height) {
                if (img.height < imageRatio.height && img.width < imageRatio.width) {
                    setCropSetting({ x:0, y:0, unit:'px', width:img.width, height:img.height })
                } else if(img.height < imageRatio.height ){
                    setCropSetting({ x:0, y:0, unit:'px', width:imageRatio.width, height:img.height});
                } else{
                    setCropSetting({ x:0, y:0, unit:'px', width: img.width, height:imageRatio.height})                    
                }
            };
        }
      return(false)
    }
  
    const getCroppedImg = (img, crop) => {
        const canvas = document.createElement("canvas");
        const ctx    = canvas.getContext('2d');
        
        if(!ctx) throw new Error ('No 2d context');

        const scaleX = img.naturalWidth / img.width;
        const scaleY = img.naturalHeight / img.height;
        
        const pixelRatio = window.devicePixelRatio;

        canvas.width  = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio)
        ctx.imageSmoothingQuality = 'high';

        const cropX   = crop.x * scaleX
        const cropY   = crop.y * scaleY
        const centerX = img.naturalWidth / 2
        const centerY = img.naturalHeight / 2

        ctx.save();

        ctx.translate(-cropX, -cropY)      
        ctx.translate(centerX, centerY)             
        ctx.scale(scale, scale)        
        ctx.translate(-centerX, -centerY)
        ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, 0, 0, img.naturalWidth, img.naturalHeight);
        ctx.restore();

        canvas.toBlob((blob) => {
            if (blob !== null) {
                blob.name = 'courselogo.png';                              
                setCroppedImage( blob );
            }
        }, "image/png", 1 );            
    }

    const onCrop = () => {
        const canvasImg = imgRef.current;

        if(!image || !canvasImg) {return Swal.fire({ icon:"warning", titleText: "Please select image..!", buttonsStyling: false, confirmButtonClass: "btn btn-brand"})};
        try {                        
             getCroppedImg(canvasImg, cropSetting)                          
          } catch (err) {          
            Swal.fire({
              icon: "error",
              titleText: "Error!",
              text: err.message,
              buttonsStyling: false,
              confirmButtonClass: "btn btn-brand",
            });
          }
    }

    const saveCroppedImg = () => {  
      if(!croppedImage){
       return Swal.fire({
          icon:"warning",
          titleText: "Please select image!",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-brand",
        });
      }
      // Call upload Api & return the image url
      try {
        helper.StartProcessing($("#save-btn"));   
          dataHandler(croppedImage)
          $("#image-cropper-modal").modal("hide");            
      } catch (err) {
        helper.StopProcessing($("#save-btn"));
        Swal.fire({
          icon: "error",
          titleText: "Error!",
          text: err.message,
          buttonsStyling: false,
          confirmButtonClass: "btn btn-brand",
        });
      }
    };
    
    const onReset = () => {
      setFile(null);
      setCroppedImage(null);
      setImage(null);
      setCropSetting({ x : 0, y : 0, unit : 'px', width : imageRatio.width, height : imageRatio.height });
      setScale(1);
  }
       
    //Modal Show /Blur Other modals
    useEffect(() => {
        $("#image-cropper-modal").modal({ backdrop: "static" });      
        $("#image-cropper-modal").on("hidden.bs.modal", function () { props.onDismissModal() });
        $("#image-cropper-modal").modal("toggle");
    }, [props.show]);

    return (
      <div className="modal fade" id="image-cropper-modal" tabIndex="-1" role="dialog" aria-hidden="true">       
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{ header && header }</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
            </div>
           
            <div className="modal-body">
				{!!imageRatio && (
					<div className="mb-2 mx-4">
						<div className="alert alert-secondary py-1" role="alert">
							<div className="alert-icon fs-rem-1_9"><i className="flaticon-exclamation-2"></i></div>
							<div className="alert-text"> Image dimensions - Max Width : {imageRatio.width} px & Height : {imageRatio.height} px. </div>						
						</div>
					</div>
				)}

                { croppedImage?(
                    <div className="d-flex justify-content-center align-items-center">
                        <img src = {window.URL.createObjectURL(croppedImage)} className="img-fluid" />
                    </div>
                )
               : (!!file) ? (<>
                    <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "25rem", minWidth: "25rem"  }} >
                        <ReactCrop                            
                            crop={cropSetting}
                            onChange={setCropSetting}                                                                                    
                            minWidth={imageRatio.width} minHeight={imageRatio.height}
                            maxWidth={imageRatio.width} maxHeight={imageRatio.height}                        
                            keepSelection={true}                                                                   
                        >
                            <img ref={imgRef} src={file} alt="image" style={{ transform:`scale(${scale})`}} onLoad={AdjustCropper} />
                        </ReactCrop>
                    </div>

                    <div className="d-flex justify-content-center align-items-center mt-2"> 
                      <div>
                        <p className="mb-2"> Adjust scale using the slider below </p>      
                        <div>
                          <input id="myRange" type="range" className="form-control-range range-slider"  
                                min="0.1" max="5.0"  step={0.05} value={scale} 
                                onChange={(e) => onScale(e)} 
                            />               
                        </div>
                      </div>                                         
                    </div>
                </>
                ) : (
                    <Dropzone imageOnly = {true} fileHandler={(f) =>{setFile(window.URL.createObjectURL(f))}} accept="image/*" />
                )}                  
            </div>
          
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal"> Close </button>

                {!!file && ( <button id="reset-btn" type="button" className="btn btn-danger margin-0 margin-r5" onClick={onReset}> Reset </button> )}
                {!croppedImage ?
                    ( <button id="crop-btn" type="button" className="btn btn-brand margin-0 " onClick={onCrop}> Crop </button> )
                    :( <button id="save-btn" type="button" className="btn btn-success margin-0" onClick={saveCroppedImg}> Save </button> )
                }                                          
            </div>
           
          </div>
        </div>
      </div>
    );
  };

export default ImageCropper;
