import { useState, useEffect} from "react";
import Select from 'react-select';
import swal from 'sweetalert2';
import moment from "moment";
import * as helper from '../global/helper';
import { useHistory } from 'react-router-dom';

import { uploadPublicFile } from "../api/fileAPI";
import ImageCropper from "../global/imageCropper";
import MatchPlayList from "./matchPlayList";
import{deleteMatchPlayData, getMasterPageData,getMatchPlayByID,getMatchPlayData,postMatchPlayData,updateMatchPlayPublish} from "../api/matchPlayAPI";
import { getTeeBoxByCourseID } from "../api/golfClubAPI";

const DeleteModal =(props)=>{
    
    //Modal Show /Blur Other modals
    useEffect(() => {
        $("#deleteModal").modal({ backdrop: "static" });      
        $("#deleteModal").on("hidden.bs.modal", function () { props?.onDismissModal() });
        $("#deleteModal").modal("toggle");
    }, [props?.show]);

    const[password, setPassword] = useState('');
    
    const deleteMaster =async()=>{
        try {
          if(!password || password == ''){
            swal.fire({ icon: 'warning', showConfirmButton: false, timer: 2500, titleText: 'Please enter the password'});
            return;
          }

          props.deleteMatchPlayMaster(password);
          $('#deleteModal').modal('hide');
          
        } catch (err) {            
            swal.fire({
                icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                titleText: 'Error!', text: err.message,
            });
            return;
        }
    }

    return(<>
        <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-hidden="true">       
            <div className="modal-dialog modal-md" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title"> Delete Match Play </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                    </div>
                
                    <div className="modal-body">
                        <div id="ModalHolder">
                            <p className="text-center" style={{fontSize: '15px'}}> Are you sure want to delete match play ? </p>

                            <div id="passwordContainer" className="margin-t25">
                                <div className="form-group row text-center">                                    
                                    <label htmlFor="txtDeletePassword" className="col-form-label col-xl-3 offset-md-2"> Password <span className="red">*</span></label>
                                    <div className="col-xl-4">
                                        <input id="txtDeletePassword" name="txtDeletePassword" type="text" className="form-control"
                                            onChange={({target})=> setPassword(target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div className="modal-footer">                        
                        <button type="button" className="btn btn-secondary margin-0 margin-r5" data-dismiss="modal"> Cancel </button>
                        <button type="button" id="btnYes" name="btnYes" className="btn btn-danger margin-0" onClick={deleteMaster}> Delete </button>                                                    
                    </div>                
                </div>
            </div>
        </div>
    </>);
}

const MatchPlayMaster = (props) => { 

    const defaultState = {
        IsOpen: true, Gender: "Both", Type: 'Singles',  MembershipType: 'Member', IsPublished: 'N',
        MenHandicapStart: 0 , MenHandicapEnd:36, WomenHandicapStart: 0 , WomenHandicapEnd:36,
        HandicapMen: 24, HandicapWomen:30 , MenAgeMin:18 ,MenAgeMax:55 ,WomenAgeMin:18 ,WomenAgeMax:55 , MenTeeBoxID:0, WomenTeeBoxID:0,    
        OpenDate: moment().format('YYYY-MM-DD HH:mm')
    };

    const history                                    					= useHistory();
    const [refetchData, setRefetchData]  = useState(false);
    const [deleteModal,  setDeleteModal] = useState(false);

    const [mMaster,  setMMaster]        = useState(defaultState);
    const [pageData, setPageData]       = useState(null);
    const [teeBoxes, setTeeBoxes]       = useState([]);

    const [isEdit,   setIsEdit]         = useState(false);
    const [flags,   setFlags]           = useState({ IsRegister: false, IsWinnerDeclared: false, IsDeadlineCreated: false });

    //<--- Cropper -->
    const [imgBlob, SetImgBlob]         = useState(null);
    const [iconBlob, SetIconBlob]       = useState(null);
    const [cropperData, setCropperData] = useState(null);
    const [matchPlayID,   setMatchPlayID]   = useState();

   //#region Effects  
        useEffect(() => {
            props.title("Match Play Master", "matchplay-master");   
            const path = history.location.pathname
            const splitedPath = path.split('/')
            const mID = splitedPath?.find(p => !isNaN(p) && p !== '');
            setMatchPlayID(mID)
            fetchMasterPageData();
        },[]);

        useEffect(() => {
            if (matchPlayID) {
                fetchMatchPlayByID(matchPlayID)
            }
        }, [matchPlayID])
   //#endregion

   //#region API Calls
        const fetchMasterPageData = async() => {
            try {
                const res = await getMasterPageData();
                const tBoxes = await getTeeBoxByCourseID(1);

                if(tBoxes && tBoxes.length > 0) {
                    setTeeBoxes(tBoxes);
                }                    
                
                if(!!res) setPageData(res);                
            } catch (error) {
                swal.fire({ icon :'error', titleText : error.message })
            }
        }

        const fetchMatchPlayByID = async (id) => {
            try {
                const res = await getMatchPlayByID(id)
                onSelectHandler(res)
            } catch (error) {
                swal.fire({ icon :'error', titleText : error.message })
            }
        }

        const fetchMatchPlayData = async(matchplayId) => {
            try {
                swal.fire({ showConfirmButton: false, onOpen:() => swal.showLoading(), titleText: 'Loading', text: 'Please Wait...',  allowOutsideClick: false, allowEscapeKey: false,});
                const res = await getMatchPlayData(matchplayId);
                if(!!res) {                     

                    setFlags({ IsRegister: res.IsRegister, IsWinnerDeclared: res.IsWinnerDeclared, IsDeadlineCreated: res.IsDeadlineCreated});    
                    swal.close();
                }              
            } catch (error) {
                swal.close();
                swal.fire({ icon :'error', titleText : error.message })
            }
        }

        const postMaster = async(btnId)=> {
            const btn = $(`#${btnId}`), form = $('#addMasterForm');
            
            //Validation Goes Here -->
            form.validate({
                rules: {
                    matchPlayName         : { required: true },
                    ddlTournamentCalendar : { required: true },
                    ddlFormat             : { required: true },
                    txtStartDate          : { required: true },        
                    txtEndDate            : { required: true }, 
                    txtMenStartHandicap   : { required: true }, 
                    txtMenEndHandicap     : { required: true }, 
                    txtWomenStartHandicap : { required: true }, 
                    txtWomenEndHandicap   : { required: true }, 
                    txtMenHandicap        : { required: true }, 
                    txtWomenHandicap      : { required: true }, 
                    txtRegistration       : { required: true },
                    txtMenMin             : { required: true },
                    txtMenMax             : { required: true },
                    txtWomenMin           : { required: true },
                    txtWomenMax           : { required: true },
                    txtCloseDate          : { required: true },
                    ddlMaleTeeBox         : { required: true },
                    ddlFemaleTeeBox       : { required: true }
               
                },
                messages: {
                    matchPlayName         : { required: "Please enter name" },
                    ddlTournamentCalendar : { required: "Please select Date" },
                    ddlFormat             : { required: "Please select Format" },
                    txtStartDate          : { required: "Please select Start Date"},
                    txtEndDate            : { required: "Please select End Date"},
                    txtMenStartHandicap   : { required: "Enter Men Handicap Start"},
                    txtMenEndHandicap     : { required: "Enter Men Handicap End"},
                    txtWomenStartHandicap : { required: "Enter Women Handicap Start"},
                    txtWomenEndHandicap   : { required: "Enter Women Handicap End"},
                    txtMenHandicap        : { required: "Enter Men Handicap"},
                    txtWomenHandicap      : { required: "Enter Women Handicap"},
                    txtRegistration       : { required: "Enter Maximum Registration"},
                    txtMenMin             : { required: "Enter Men Age"},
                    txtMenMax             : { required: "Enter Men Age"},
                    txtWomenMin           : { required: "Enter Women Age"},
                    txtWomenMax           : { required: "Enter Women Age"},
                    txtCloseDate          : { required: "Please Select Close Date"},
                    ddlMaleTeeBox         : { required: "Please Select Tee Box"},
                    ddlFemaleTeeBox       : { required: "Please Select Tee Box"}
                }
            });
            
            if (!form?.valid()) return;
          
            try {                     
                const matchPlayMaster      = mMaster;               
                                                            
                swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading(),  allowOutsideClick: false, allowEscapeKey: false, });
                helper.StartProcessing(btn);

                //Image & Icon Call upload API -->
                if(imgBlob){
                    const res = await uploadPublicFile(imgBlob, 'Content');
                    matchPlayMaster.Image = res.fileName;            
                }else{
                    if(matchPlayMaster?.Image) matchPlayMaster.Image = matchPlayMaster?.Image.split('/Content/')[1];
                }

                if(iconBlob){
                    const res = await uploadPublicFile(iconBlob, 'Content');
                    matchPlayMaster.Icon = res.fileName;            
                }else{
                    if(matchPlayMaster?.Icon) matchPlayMaster.Icon = matchPlayMaster?.Icon.split('/Content/')[1];
                }

                //Description
                const Content = window.getEditorValue("#txtDescription");
                if(!!Content) matchPlayMaster.Description = Content;

                matchPlayMaster.IsPublished = (btnId =='btnPublish') ? "Y": "N";

                //API Call to Post MatchPlay Data -->          
                await postMatchPlayData(matchPlayMaster);
                
                if(!matchPlayMaster.ID){
                    swal.fire({ icon: 'success', showConfirmButton: false, timer: 2500,  
                        titleText: 'Master added successfully', 
                        onOpen: () => swal.hideLoading()                                     
                    });
                }else{
                    swal.fire({ toast: true, icon: 'success', position: 'bottom-end', showConfirmButton: false, timer: 2500,  
                        titleText: 'Master updated successfully', 
                        onOpen: () => swal.hideLoading()                                     
                    });
                }
                setRefetchData(true);
                helper.StopProcessing(btn);
            } catch (err) {
                helper.StopProcessing(btn);
                swal.fire({
                    icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                    titleText: 'Error!', text: err.message,
                });
                return;
            }
        }

        const deleteMatchPlayMaster = async(pswd)=>{
            const btn = $(`#btnDelete`);
            try {                
                swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading() });
                helper.StartProcessing(btn);                
                
                await deleteMatchPlayData(mMaster?.ID, pswd);

                swal.fire({ icon: 'success', showConfirmButton: false, timer: 2500,  
                    titleText: 'Master Deleted successfully',  onOpen: () => swal.hideLoading()                                     
                });
                setRefetchData(true);
                helper.StopProcessing(btn);
            } catch (err) {       
                helper.StopProcessing(btn);       
                swal.fire({
                    icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                    titleText: 'Error!', text: err.message,
                });
                return;
            }
        }

        const updatePublish = async(btnId, isPublish)=> {
            const btn = $(`#${btnId}`);

            try {
                swal.fire({ titleText: 'Loading', text: 'Please Wait...', showConfirmButton: false, onOpen: () => swal.showLoading(),  allowOutsideClick: false, allowEscapeKey: false, });
                helper.StartProcessing(btn);                                

                //API Call to Post Tournament Data -->             
                await updateMatchPlayPublish(mMaster.ID, isPublish);

                swal.fire({ icon: 'success', showConfirmButton: false, timer: 2500,  
                    titleText: 'Master Updated successfully', 
                    onOpen: () => swal.hideLoading()                                     
                });

                setRefetchData(true);
                helper.StopProcessing(btn);
            } catch (err) {
                helper.StopProcessing(btn);
                swal.fire({
                    icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                    titleText: 'Error!', text: err.message,
                });
                return;
            }
        }
       


   //#endregion
    
   //#region handlers 
        const dataHandler = (field, value) => {       
            if(field == "IsOpen" && !value)  setMMaster(d => ({...d, OpenDate: moment().format('YYYY-MM-DD HH:mm')})); //RESET OpenDate to Current Date is if false   
            
            setMMaster(d => ({...d, [field]: value}));   
        }

        const openCropper = (imgType) =>{
            if(imgType == "Icon"){
                setCropperData({
                    header      : 'Upload Icon',
                    imageRatio  : { width : 64, height : 64},
                    dataHandler : (val) =>  SetIconBlob(val),
                })
            }else{
                setCropperData({
                    header      : 'Upload Banner',
                    imageRatio  : { width : 500, height : 300},
                    dataHandler : (val) =>  SetImgBlob(val),
                })
            }           
        }

        //onMasterSelect
        const onSelectHandler = (mMaster)=> {
            if(!mMaster) return;
            setMMaster(mMaster);            
            if(!!mMaster.Description) setTimeout(()=>{ window.setEditorValue('#txtDescription', mMaster.Description)}, 200)                        
            
            fetchMatchPlayData(mMaster?.ID);         
            setIsEdit(true);
        }

        const onDeleteMaster= async()=> {         
            if(!mMaster?.ID) return;

            try {                        
                //check validations
                const isRegistered = flags?.IsRegister; //Update this After Registration

                if(!isRegistered){                   
                    deleteMatchPlayMaster('noPass');
                }else{
                    setDeleteModal(true);
                }          
            } catch (err) {              
                swal.fire({
                    icon: 'error', buttonsStyling: false, confirmButtonClass: 'btn btn-brand',
                    titleText: 'Error!', text: err.message,
                });
                return;
            }           
        }

               
        //Reset on drawer close
        const onReset =()=> {
            setIsEdit(false)
            setMMaster(defaultState);
            setTeeBoxes([]);
            SetImgBlob(null);
            SetIconBlob(null);
            setCropperData(null);
            setFlags({ IsRegister: false, IsDeadlineCreated: false, IsWinnerDeclared: false });
        } 
    //#endregion

    return(<>
        <MatchPlayList matchPlayID={matchPlayID} setMatchPlayID={setMatchPlayID} title={"Match Play Master"} comp={'Master'} refetchData={refetchData} resetRefetch={()=> setRefetchData(false)}  defaultState={defaultState} stateHandler={setMMaster} onSelectHandler={onSelectHandler} resetData={onReset} >          
            <div className="kt-portlet">
                <div className="kt-portlet__body padding-t30 padding-b0">
                    <form className="kt-form" id="addMasterForm" role="form">
                        <div id="ControlHolder" className="kt-form kt-form--label-right padding-10">                                       
                            
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Match Play Name <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <input 
                                        id="matchPlayName"  name="matchPlayName" 
                                        type="text" className="form-control"
                                        value={mMaster.Name || ''}
                                        onChange={({target}) => dataHandler("Name", target.value)}  
                                    />
                                </div>
                            </div> 

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Tournament Calender <span className="red">*</span></label>
                                <div className="col-xl-3 col-lg-4">
                                    <Select
                                        id       = "ddlTournamentCalendar" className= "ddlTournamentCalendar" name= "ddlTournamentCalendar" placeholder= "Select Calendar"
                                        options  = {pageData?.TournamentCalendar || []}
                                        value    = {(pageData?.TournamentCalendar && pageData?.TournamentCalendar.length > 0 && mMaster.TournamentCalendarID) && pageData?.TournamentCalendar.find(m => m.value == mMaster.TournamentCalendarID)}
                                        onChange = {(event) => dataHandler('TournamentCalendarID', event.value)}
                                    />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Opens To <span className="red">*</span></label>
                                <div className="col-xl-3 col-lg-3">
                                    <div className="input-group">                                       
                                        <select id="ddlMembership" name="ddlMembership" title="Select" className="form-control"
                                            value={mMaster?.MembershipType} disabled={flags?.IsRegister}
                                            onChange={({target}) => dataHandler("MembershipType", target.value)}
                                        >
                                            {pageData?.MembershipType.map((g) => ( <option key={g} value={g}> {g} </option> ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Playing Format <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <div className="input-group">
                                        <select id="ddlType" name="ddlType" title="Select" className="form-control" 
                                          value={mMaster?.Type} disabled={flags?.IsRegister}
                                          onChange={({target}) => dataHandler("Type", target.value)}    
                                        >
                                            {pageData?.MatchPlayType.map((g) => ( <option key={g} value={g}> {g} </option> ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Match Play Format <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <Select
                                        id       = "ddlFormat" className= "ddlFormat" name= "ddlFormat" placeholder= "Select Format"
                                        options  = {pageData?.MatchPlayFormats.filter(f => f.Type == mMaster.Type).map(c => ({
                                            label: c.Format,
                                            value: c.Format
                                        }))}
                                        value    = {(mMaster.Format) && {label: mMaster.Format, value: mMaster.Format}}
                                        disabled={flags?.IsRegister}
                                        onChange = {(event) => dataHandler('Format', event.value)}
                                    />
                                </div>
                            </div> 

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Gender <span className="red">*</span></label>
                                <div className="col-xl-3 col-lg-3">
                                    <div className="input-group">                                       
                                        <select id="ddlGender" name="ddlGender" title="Select" className="form-control"
                                            value={mMaster?.Gender} disabled={flags?.IsRegister}
                                            onChange={({target}) => dataHandler("Gender", target.value)}
                                        >
                                            {pageData?.Genders.map((g) => ( <option key={g} value={g}> {g} </option> ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Registration Age limit */}
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Registration Age limit <span className="red">*</span></label>

                                {mMaster?.Gender != "Female" && (<>
                                    <div className="col-xl-2 col-lg-2 hc_men"> Men Min                                                     
                                        <input id="txtMenMin" name="txtMenMin" type="number" className="form-control"                                             
                                            value={mMaster?.MenAgeMin} disabled={flags?.IsRegister}
                                            onChange={({target}) => dataHandler("MenAgeMin", target.value)}
                                        />
                                    </div>                                               
                                    <div className="col-xl-2 col-lg-2 hc_men"> Men Max                                                  
                                        <input id="txtMenMax" name="txtMenMax" type="number" className="form-control"                                         
                                            value={mMaster?.MenAgeMax}  disabled={flags?.IsRegister}
                                            onChange={({target}) => dataHandler("MenAgeMax", target.value)}
                                        />
                                    </div>
                                </>)}

                                {mMaster?.Gender != "Male" && (<>
                                    <div className="col-xl-2 col-lg-2 hc_women"> Women Min
                                        <input id="txtWomenMin" name="txtWomenMin" type="number" className="form-control"                                        
                                          value={mMaster?.WomenAgeMin} disabled={flags?.IsRegister}
                                          onChange={({target}) => dataHandler("WomenAgeMin", target.value)}
                                       />
                                    </div>
                                    <div className="col-xl-2 col-lg-2 hc_women"> Women Max
                                            <input id="txtWomenMax" name="txtWomenMax" type="number" className="form-control"                                             
                                                value={mMaster?.WomenAgeMax} disabled={flags?.IsRegister}
                                                onChange={({target}) => dataHandler("WomenAgeMax", target.value)}
                                            />
                                    </div>
                                </>)}                                                                
                            </div>

                            {/* Registration Handicap */}
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Registration Handicap <span className="red">*</span></label>

                                {mMaster?.Gender != "Female" && (<>
                                    <div className="col-xl-2 col-lg-2 hc_men"> Men Min                                                     
                                        <input id="txtMenStartHandicap" name="txtMenStartHandicap" type="number" className="form-control"                                             
                                            value={mMaster?.MenHandicapStart} disabled={flags?.IsRegister}
                                            onChange={({target}) => dataHandler("MenHandicapStart", target.value)}
                                        />
                                    </div>                                               
                                    <div className="col-xl-2 col-lg-2 hc_men"> Men Max                                                  
                                        <input id="txtMenEndHandicap" name="txtMenEndHandicap" type="number" className="form-control"                                         
                                            value={mMaster?.MenHandicapEnd}  disabled={flags?.IsRegister}
                                            onChange={({target}) => dataHandler("MenHandicapEnd", target.value)}
                                        />
                                    </div>
                                </>)}

                                {mMaster?.Gender != "Male" && (<>
                                    <div className="col-xl-2 col-lg-2 hc_women"> Women Min
                                        <input id="txtWomenStartHandicap" name="txtWomenStartHandicap" type="number" className="form-control"                                        
                                          value={mMaster?.WomenHandicapStart} disabled={flags?.IsRegister}
                                          onChange={({target}) => dataHandler("WomenHandicapStart", target.value)}
                                       />
                                    </div>
                                    <div className="col-xl-2 col-lg-2 hc_women"> Women Max
                                            <input id="txtWomenEndHandicap" name="txtWomenEndHandicap" type="number" className="form-control"                                             
                                                value={mMaster?.WomenHandicapEnd} disabled={flags?.IsRegister}
                                                onChange={({target}) => dataHandler("WomenHandicapEnd", target.value)}
                                            />
                                    </div>
                                </>)}                                                                
                            </div>

                             {/* Max Handicap */}
                             <div className="form-group row">
                                <label className="col-form-label col-lg-3">Max Handicap <span className="red">*</span></label>
                                {(mMaster?.Gender != "Female") && (<>
                                    <div className="col-xl-2 col-lg-2 hc_men"> Men
                                        <input id="txtMenHandicap" name="txtMen" type="number" className="form-control"
                                        value={mMaster?.HandicapMen || 24} disabled={flags?.IsRegister}
                                        onChange={({target}) => dataHandler("HandicapMen", parseInt(target.value))}   
                                    />
                                    </div>
                                </>)}
                                
                                {(mMaster?.Gender != "Male") && (<>
                                    <div className="col-xl-2 col-lg-2 hc_women"> Women
                                        <input id="txtWomenHandicap" name="txtWomen" type="number" className="form-control"
                                            value={mMaster?.HandicapWomen || 30} disabled={flags?.IsRegister}
                                            onChange={({target}) => dataHandler("HandicapWomen", parseInt(target.value))}   
                                        />
                                    </div>
                                </>)}
                            </div>

                            {/* Banner Img */}
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Banner Image </label>
                                <div className="col-sm-9">                                                        
                                    {(!!imgBlob || mMaster.Image) &&(<>
                                        <img height={150} src={imgBlob ? URL.createObjectURL(imgBlob) : mMaster.Image && (mMaster.Image) }/>
                                    </>) }
                                    <p className="mt-3">
                                        <button id="btn_Logo_Image" type="button" className="btn btn-secondary" onClick={() => openCropper('Banner')}>
                                            {(!!imgBlob || mMaster.Image) ? 'Change Image' : 'Upload Image'}
                                        </button>
                                    </p>
                                    <p className="">(500 W x 300 H pixels)</p>                                                       
                                </div>
                            </div>

                            {/* Trophy Icon */}
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Trophy Icon </label>
                                <div className="col-sm-9">                                                        
                                    {(!!iconBlob || mMaster.Icon) &&(<>
                                        <img height={150} src={iconBlob ? URL.createObjectURL(iconBlob) : mMaster.Icon && (mMaster.Icon) }/>
                                    </>) }
                                    <p className="mt-3">
                                        <button id="btn_Logo_Icon" type="button" className="btn btn-secondary" onClick={() => openCropper('Icon')}>
                                            {(!!iconBlob || mMaster.Icon) ? 'Change Image' : 'Upload Image'}
                                        </button>
                                    </p>
                                    <p className="">(64 W x 64 H pixels)</p>                                                       
                                </div>
                            </div>                 
                                     
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Description </label>
                                <div className="col-xl-6 col-lg-8">
                                    <textarea id="txtDescription" name="txtDescription" className="form-control" rows="3"></textarea>
                                </div>
                            </div>

                            {/* EventNo */}
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3"> Event No </label>
                                <div className="col-xl-6 col-lg-8">
                                    <input id="txtEventNo" name="txtEventNo" type="text" className="form-control" value={mMaster?.EventNo || ''}
                                       onChange={({target}) => dataHandler("EventNo", parseInt(target.value))} />
                                </div>
                            </div>

                            
                            {/* Start Date*/}
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Match Play Start Date <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <div className="input-group date">
                                        <input id="txtStartDate" name="txtStartDate" type="text" className="form-control date-input" readOnly={true} placeholder="Select start date"
                                            value={mMaster?.StartDate ? moment(mMaster.StartDate).format('DD-MM-YYYY') : ''}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text"> <i className="la la-calendar-check-o"></i> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* End Date */}            
                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Match Play End Date <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <div className="input-group date">
                                        <input id="txtEndDate" name="txtEndDate" type="text" className="form-control date-input" readOnly={true} placeholder="Select end date"
                                            value={mMaster.EndDate ? moment(mMaster.EndDate).format('DD-MM-YYYY') : ''}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text"> <i className="la la-calendar-check-o"></i> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Registration Open On Publish</label>
                                <div className="col-xl-6 col-lg-8">
                                    <span className="kt-switch kt-switch--icon">
                                        <label>
                                            <input id="chkOpen" name="chkOpen" type="checkbox" defaultChecked="checked"  
                                                value={mMaster?.IsOpen} 
                                                disabled={flags?.IsRegister}
                                                onChange={({target}) => dataHandler("IsOpen", target.checked)}    
                                            />
                                            <span></span>
                                        </label>
                                    </span>
                                </div>
                            </div>
                                                     
                            <div id="pnlOpen" className={`form-group row ${!(mMaster?.IsOpen)? '' : 'hidden'}`}>
                                <label className="col-form-label col-lg-3">Registration Open Date <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <div className="input-group date">
                                        <input id="txtOpenDate" name="txtOpenDate" type="text" className="form-control dateTime-input" readOnly={true}  placeholder="Select open date" 
                                            value={mMaster.OpenDate ? moment(mMaster.OpenDate).format('YYYY-MM-DD HH:mm'): ''} 
                                            disabled={flags?.IsRegister}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text"> <i className="la la-calendar-check-o"></i> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Registration Close Date <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <div className="input-group date">
                                        <input id="txtCloseDate" name="txtCloseDate" type="text" className="form-control dateTime-input" readOnly={true} placeholder="Select close date" 
                                            value={mMaster.CloseDate ? moment(mMaster.CloseDate).format('YYYY-MM-DD HH:mm'): ''} 
                                            disabled={flags?.IsDeadlineCreated}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text"> <i className="la la-calendar-check-o"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row">
                                <label className="col-form-label col-lg-3">Maximum Registration <span className="red">*</span></label>
                                <div className="col-xl-6 col-lg-8">
                                    <input 
                                        id="txtRegistration"  name="txtRegistration" 
                                        type="text" className="form-control"
                                        value={mMaster.RegisterLimit || ''}
                                        disabled={flags?.IsRegister}
                                        onChange={({target}) => dataHandler("RegisterLimit", parseInt(target.value))}  
                                    />
                                </div>
                            </div> 

                            <div id="pnlTeeBox">
                                <div className="form-group row">
                                    {(mMaster.Gender == 'Both' || mMaster.Gender == 'Male') && (
                                        <>
                                            <label className="col-form-label col-lg-3 hc_men"> Male Teebox <span className="red">*</span></label>
                                            <div className="col-xl-2 col-lg-2 ddl_teebox_master hc_men">                                                           
                                                <Select
                                                    value={(teeBoxes && teeBoxes.length > 0 && mMaster.MenTeeBoxID) && {label: teeBoxes.find(m => m.ID == mMaster.MenTeeBoxID).Name, value: mMaster.MenTeeBoxID}}
                                                    onChange={(event) => dataHandler('MenTeeBoxID', event.value)}
                                                    name="ddlMaleTeeBox"
                                                    placeholder="Select Tee Box"
                                                    id="ddlMaleTeeBox"
                                                    className="ddlMaleTeeBox"
                                                    options={teeBoxes.filter(f => f.Gender == "Male").map(c => ({
                                                        label: c.Name,
                                                        value: c.ID
                                                    }))}
                                                />
                                            </div>
                                        </>
                                    )}

                                    {(mMaster.Gender == 'Both' || mMaster.Gender == 'Female') && (
                                        <>
                                            <label className="col-form-label col-lg-3 hc_women"> Female Teebox <span className="red">*</span></label>
                                            <div className="col-xl-2 col-lg-2 ddl_teebox_master hc_women"> 
                                                <Select
                                                    value={(teeBoxes && teeBoxes.length > 0 && mMaster.WomenTeeBoxID) && {label: teeBoxes.find(m => m.ID == mMaster.WomenTeeBoxID).Name, value: mMaster.WomenTeeBoxID}}
                                                    onChange={(event) => dataHandler('WomenTeeBoxID', event.value)}
                                                    name="ddlFemaleTeeBox"
                                                    placeholder="Select Tee Box"
                                                    id="ddlFemaleTeeBox"
                                                    className="ddlFemaleTeeBox"
                                                    options={teeBoxes.filter(f => f.Gender == "Female").map(c => ({
                                                        label: c.Name,
                                                        value: c.ID
                                                    }))}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div> 
             

                            <div className="kt-portlet__foot">
                                <div className="kt-form__actions">
                                    <div className="row">
                                        <div className="col-lg-9 ml-lg-auto">
                                            <button id="btnSave" type="button" className="btn btn-brand d-inline-block" 
                                                onClick={() => postMaster('btnSave')}
                                            > Save </button>        
                                            
                                            {(mMaster?.IsPublished == "Y") ? (
                                                <button id="btnUnPublish" type="button" className="btn btn-warning d-inline-block ml-4 text-white" 
                                                    onClick={() => updatePublish('btnUnPublish', "N")}
                                                > UnPublish </button> 
                                            ):((flags?.IsWinnerDeclared)?(<>
                                                    <button id="btnTournamentPublish" type="button" className="btn btn-success d-inline-block ml-4" 
                                                        onClick={() => updatePublish('btnTournamentPublish', "Y")}
                                                    > Publish </button>            
                                                </>):(<>
                                                    <button id="btnPublish" type="button" className="btn btn-success d-inline-block ml-4" 
                                                        onClick={() => postMaster('btnPublish')}
                                                    > Save & Publish </button>                                                            
                                                </>)                                                
                                            )}    

                                            {(!!isEdit) && (                                                    
                                                <button id="btnDelete" type="button" className="btn btn-danger d-inline-block ml-4" 
                                                    onClick={() => onDeleteMaster()}
                                                > Delete </button>       
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>                      
        </MatchPlayList>

    {cropperData && (
        <ImageCropper
            show           = { cropperData }
            header         = { cropperData.header }   
            imageRatio     = { cropperData.imageRatio }
            dataHandler    = { cropperData.dataHandler }
            onDismissModal = {() => setCropperData(null) }
        />
    )}

    {deleteModal && (
        <DeleteModal
            show           = { deleteModal }         
            onDismissModal = {() => setDeleteModal(null) }
            deleteTournamentMaster = {(v)=> deleteMatchPlayMaster(v)}
        />
    )}        

    </>)    
}

export default MatchPlayMaster;