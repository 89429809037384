import { useState, useEffect} from "react";

const closeDrawer = () =>{
    // window.removeCkeditor("editorContent");
    Config.Core.CloseDrawer();
}

const MatchPlayRound = (props) => { 


    return(<>
     <MatchPlayList title={"Match Play Deadline"} comp={'Deadline'} >
        <div className="kt-portlet">
            <div className="kt-portlet__body padding-t30 padding-b0">
                <form className="kt-form" id="addRoundForm" role="form">
                    <div id="ControlHolder" className="kt-form kt-form--label-right padding-10">

                        <div className="form-group row">
                            <label className="col-form-label col-lg-3">Round 1 <span className="red">*</span></label>
                            <div className="col-xl-6 col-lg-8">
                                <input 
                                    id="matchPlayName"  name="matchPlayName" 
                                    type="text" className="form-control"
                                    value={mMaster.Name || ''}
                                    onChange={({target}) => dataHandler("Name", target.value)}  
                                />
                            </div>
                        </div> 

                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <div className="row">
                                    <div className="col-lg-9 ml-lg-auto">
                                        <button id="btnSave" type="button" className="btn btn-brand d-inline-block">Save</button>
                                        <button id="btnCancel" type="button" className="btn btn-secondary margin-l10" onClick={closeDrawer}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
     </MatchPlayList>
    </>)
}

export default MatchPlayRound;